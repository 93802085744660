.cellHeight{
    line-height: 10px;
}

.rfValue {
    text-align: right;
    font-weight: bold;
    width: 55px;
}

.rfValueTotals {
    @extend cellHeight;
    text-align: center;
    font-weight: bold;
}

.rfValueWhite {
    text-align: right;
    font-weight: bold;
    background-color: #fff;
}

.rfValueGray {
    text-align: right;
    font-weight: bold;
    background-color: #c3c3c3c3;
}

.rfTextGray {
    text-align: left;
    font-weight: bold;
    background-color: #999999;
    white-space: pre;
}

.rfTextWhite {
    text-align: left;
    font-weight: bold;
    background-color: #fff;
    white-space: pre;
}

.rfEmptyCell {
    text-align: right;
    font-weight: bold;
    background-color: #a0a0a0;
}

.rfHeaderGrayCenter {
    background-color: #e5e5e5;
    text-align: center;
    font-weight: bold;
    line-height: .4;
}

.rfValueMilestoneHeader {
    text-align: left;
    font-weight: bold;
    color: #554323;
    padding: 0px;
    border: 0px;
}

.rfValueMilestoneHeaderGray {
    text-align: left;
    font-weight: bold;
    color: #333333;
    background-color: #999999;
    padding: 0px;
    border: 0px;
}

.rfWarning {
    text-align: right;
    background-color: #ffc107;
    border: 1px solid #000;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: bold;
    font-stretch: normal;
    font-size: 12px;
    line-height: 1.34;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    white-space: nowrap;
    color: #000;
    width: 10px;
}

.rfError {
    text-align: right;
    background-color: #c82333;
    border: 1px solid #000;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: bold;
    font-stretch: normal;
    font-size: 12px;
    line-height: 1.34;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    white-space: nowrap;
    color: #fff;
    width: 10px;
}

.rfSavePending {
    background-color: #96f0aa;
    border: 1px solid #000;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: bold;
    font-stretch: normal;
    font-size: 12px;
    line-height: 1.34;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    white-space: nowrap;
    color: #fff;
}

.rfLastUpdated {
    background-color: rgb(255 255 255 / 0.00);
    border: 0px solid #000;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: normal;
    font-stretch: normal;
    font-size: 11px;
    line-height: 1.34;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    white-space: nowrap;
    color: #333333;
}

.rfUpdated {
    background-color: #28a745;
    border: 1px solid #000;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: bold;
    font-stretch: normal;
    font-size: 12px;
    line-height: 1.34;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    white-space: nowrap;
    color: #fff;
}

.rfFailedUpdate {
    background-color: #4528a7;
    border: 1px solid #000;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: bold;
    font-stretch: normal;
    font-size: 12px;
    line-height: 1.34;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    white-space: nowrap;
    color: #fff;
}

.rfHeader {
    color: black;
}

table {
    border-collapse: collapse;
}

TD {
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-stretch: normal;
    white-space: pre;
}