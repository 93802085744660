.my-project-header-style {
    grid-column: 2/4;
    grid-row: 3;
    padding-left: 10px;
    font-size: 16px;
    opacity: 0.8;
    line-height: 4.0;
    color: red;
}

.my-projects {
    padding-left: 0px;
    text-align: left;
    padding-left: 2px;
}
