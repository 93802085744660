.myProjectHeaderStyles {
    grid-column: 2/4;
    grid-row: 3;
    font-size: 16px;
    font-weight: 100;
    opacity: 0.8;
    line-height: 3.0;
}

.projectDetails {
    @extend myProjectHeaderStyles;
    width: 5%;
    max-width: 1%;
    text-align: center;
}

.myProjects {
    padding-left: 0px;
    text-align: left;
    padding-left: 2px;
}

.forecastLinkStyles {
    color: #000000;
    background-color: #E9C71D;
    border-color: #E9C71D;
    font-size: 14px;
    line-height: 2;
    border-radius: 3px;
    padding: 6px 6px;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
    font-family: Urbanist;
}

.linkStyles {
    text-decoration: none;
    color: blue;
}
