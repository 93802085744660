.baseRow {
    flex-grow: 1;
    border: "1px solid grey";
}

.buttonSpacing {
    margin-top: 20px;
}

.buttonColor {
    background-color: #6AA2DC;
}

.savePending {
    background-color: #28a745;
    border: 1px solid #000;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: bold;
    font-stretch: normal;
    font-size: 12px;
    line-height: 1.34;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    white-space: nowrap;
    color: #fff;
    max-width: fit-content;
}

